import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import uniqueID from 'helpers/uniqueID';


export const svgProps = {
  height: PropTypes.number,
  width: PropTypes.number,
  viewBoxheight: PropTypes.number,
  viewBoxwidth: PropTypes.number,
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.bool,
  constrained: PropTypes.oneOf(['height', 'width']),
  ariaLabel: PropTypes.string,
  color: PropTypes.string
};

export default function SvgContainer({
  title, description, viewBoxWidth, viewBoxHeight, constrained, children,
  icon, height, width, className, fill, ariaLabel, ariaHidden, minWidth, color
}) {
  const id = useMemo(() => `id${uniqueID()}`, []);
  const titleId = title ? `t${id}` : '';
  const descriptionId = description ? `d${id}` : '';
  const labeledBy = `${titleId} ${descriptionId}`;

  const viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
  const ratio = (viewBoxWidth / viewBoxHeight) * 100;

  const derivedStyle = !icon && constrained === 'height' ? { paddingBottom: `${ratio}%` } : {};
  const finalStyle = { ...derivedStyle, ...(minWidth && { minWidth }) };

  const svgClass = classNames('SvgContainer', { [`constrained-${constrained}`]: !!constrained }, className);
  const preserveAspectRatio = constrained ? "xMidYMin slice" : '';

  const deriveSize = (side) => {
    if (fill) return '100%';
    if (constrained) return null;
    if (icon) return "1em";
    return side;
  };

  let ariaProps = { ...((ariaHidden !== undefined) && ({ 'aria-hidden': true })) };
  if (ariaLabel) {
    ariaProps = { ...ariaProps, 'aria-label': ariaLabel };
  } else {
    ariaProps = { ...ariaProps, 'aria-labelledby': labeledBy };
  }

  return (

    <svg
      className={svgClass}
      viewBox={viewBox}
      preserveAspectRatio={preserveAspectRatio}
      xmlns="http://www.w3.org/2000/svg"
      height={deriveSize(height)}
      width={deriveSize(width)}
      style={finalStyle}
      {...ariaProps}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      {description ? <desc id={descriptionId}>{description}</desc> : null}
      {children}
    </svg>

  );
}

SvgContainer.propTypes = {
  ...svgProps
};

SvgContainer.defaultProps = {
  viewBoxHeight: 32,
  viewBoxWidth: 32,
  height: 32,
  width: 32,
};

// withSvgContainer is a HoC function which accepts overrides for SvgContainer.defaultProps as arguments
export const withSvgContainer = (SvgIcon, title, viewBoxWidth, viewBoxHeight) => props => (
  <SvgContainer {...{ ...{ title, viewBoxWidth, viewBoxHeight }, ...props }}>
    <SvgIcon {...props} />
  </SvgContainer>
);

/* eslint-disable camelcase */
import { detect } from 'detect-browser';
import localForage from 'localforage';

function inIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
// Specify the API endpoint for user data
export const apiRoot = inIframe() ? '/api/ati/v1/' : '/api/web/v1/';

function getConfigData() {
  const config = document.getElementById('js-config-data');
  const data = JSON.parse(config.getAttribute('data-config-object'));
  return config ? data.attributes : {};
}

const configData = getConfigData();

// Each variable should get exported so they can be individually imported
export const loaded = false;
export const browser = detect();
export const env = configData.environment;

// Should the root urls be defined here as they are not dependent on any rails varaibles?
export const loginRoot = '/users/sign_in';

// Change the boolean values to enable/disable console logging but do not change the other conditions
export const apiConsoleLogging = configData.api_console_logging;
export const contextReducerLogging = browser.name !== 'ie' && configData.context_reducer_logging?.downcase === 'true';
export const qaSessionTimeoutOn = configData.qa_session_timeout_on?.downcase === 'true';
export const LaunchDarklyClientId = configData.launch_darkly_client_id;
export const BugFenderAppKey = configData.bug_fender_app_key;

export const hubspot = {
  shouldSync: configData.hubspot_should_sync?.downcase === 'true',
  platformId: configData.hubspot_platform_id || '00000',
  signUpFormId: configData.hubspot_sign_up_form_id,
  newsletterFormId: configData.hubspot_newsletter_form_id,
  cmeCoachFormId: configData.hubspot_cme_coach_form_id,
  institutionalLandingFormId: configData.hubspot_institutional_landing_form_id
};

export const algolia = {
  appId: configData.angolia_app_id,
  apiKey: configData.angolia_api_key,
};

export const stripe = {
  publicKey: configData.stripe_public_key,
};

export function onAPIUnauthorized(response) {
  if (response.data.errors.some(e => e.code === 'SESSION_REQUIRED')) {
    // eslint-disable-next-line no-restricted-globals
    location.href = loginRoot;
  }
}

export const googleAnalyticsId = configData.google_analytics_id;
export const googleAnalyticsMeasurementId = configData.google_analytics_measurement_id;
export const googleAnalyticsTagManagerId = configData.google_analytics_tag_manager_id;
export const apiTimeout = env === 'development' && (configData.api_timeout?.to_i || 10000);

export const clearLocalStorage = () => {
  localForage.clear();
};

export const clearSessionStorage = () => {
  window.sessionStorage.clear();
};

export const uaWidgetURLs = {
  app: configData.ua_app,
  cas: configData.ua_cas,
  api: configData.ua_api,
  service: configData.ua_service,
  customerService: configData.ua_customerService,
  script: configData.ua_script,
  style: configData.ua_style
};

// The default export is an object with all the config variables
export default {
  browser,
  env,
  loginRoot,
  apiRoot,
  apiConsoleLogging,
  contextReducerLogging,
  qaSessionTimeoutOn,
  hubspot,
  algolia,
  onAPIUnauthorized,
  googleAnalyticsId,
  googleAnalyticsMeasurementId,
  googleAnalyticsTagManagerId,
  apiTimeout,
  clearLocalStorage,
  clearSessionStorage,
  LaunchDarklyClientId,
  BugFenderAppKey
};

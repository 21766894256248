import React, {
  useState, useRef, useEffect, useCallback
} from "react";
import classNames from 'classnames';

import Input, { InputAppend } from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import NavLink from 'components/menus/NavLink';
import { useOnClickOutside } from 'hooks/keypress';
import { algoliaProductsSearch } from 'actions/algolia';
import { debounceFunction } from 'hooks/react';


export default function SpecialtySearch() {
  const [keywordFilter, setKeywordFilter] = useState('');
  const [products, setProducts] = useState(null);
  const [focus, setFocus] = useState('');
  const formRef = useRef();

  useOnClickOutside(formRef, () => setFocus(false));

  const startAlgoliaSearch = async (passedKeyword) => {
    try {
      const keyword = passedKeyword ? passedKeyword.trim() : '';
      const algoliaProducts = await algoliaProductsSearch(keyword);
      setProducts(algoliaProducts);
    } catch (error) {
      console.error(error);
    }
  };

  const debounceStartSearch = useCallback(
    debounceFunction(startAlgoliaSearch, 1000), []
  );

  useEffect(() => {
    debounceStartSearch(keywordFilter);
  }, [keywordFilter]);


  const formClass = classNames('search-form', { focus });

  const highlightReplace = (inputString) => {
    if (!inputString.includes('<em>')) return inputString.toUpperCase();
    const arr = inputString.split(' ');
    // Because JSX escapes all the tags in strings that are not added by code
    return (
      <>
        {arr.map((el) => {
          if (!el.includes('<em>')) return el.concat(" ").toUpperCase();
          const stripped = el.replace("<em>", "").replace("</em>", "");
          return <span className="bg-light-orange">{stripped.concat(" ").toUpperCase()}</span>;
        })}
      </>
    );
  };

  return (
    <div className="SpecialtySearch form-group py-1 mb-0 ml-2 dropdown">
      <form
        className={formClass}
        onSubmit={() => {}}
        ref={formRef}
        onClick={() => setFocus(true)}
        role="presentation"
      >
        <Input
          name="specialtyFilter"
          className="shadow-none border-gray"
          placeholder="Search Specialty/Exam"
          onChange={setKeywordFilter}
          value={keywordFilter}
          small
        >
          <InputAppend>
            <Button
              type="submit"
              icon="Search"
              styleVariant="light"
              ariaLabel="Search"
              iconProps={{ ariaHidden: true, height: 20, width: 20 }}
              className="shadow-none border-gray border-left-0 bg-white"
              disabled
            />
          </InputAppend>
        </Input>
        {!!products && products.hits.length > 0 && (
          <div className={`dropdown-menu dropdown-menu-right ${focus ? 'show' : 'hide'}`}>
            {products.hits.map(p => (
              <NavLink
                styleVariant="dropdown-item"
                href={`/${p.path}`}
                key={p.objectID}
              >
                {highlightReplace(p._highlightResult.name.value)}
              </NavLink>
            ))}
          </div>
        )}
      </form>
    </div>
  );
}

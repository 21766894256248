import Q from 'q';
import CartItem from './cart_item';
import Referral from './referral_validator';
class ShoppingCart {
  constructor(opts = {}) {
    this.items = [];

    // Credit Card Data
    // The keys for this object is needed to match the Stripe API
    this.credit_card = {
      name: null,
      number: null,
      exp_month: null,
      exp_year: null,
      cvc: null,
      address_zip: null
    };

    this.additional_info = {
      npi_name: null,
      npi_number: null,
      phone_number: null,
      mailing_address: null
    };

    // Other
    this.errors = [];
    this.ready = false;
    this.currStep = null;
    this.coupon = {};
    this.referral = new Referral();
    this.hasGiftCard = false;
  }

  showCmeRestrictedModal() {
    console.log("Launching CME Restricted Modal");
    $('#cme-purchase-restricted-modal').modal('show');
  }

  getCartItems() {
    return $.ajax({
      method: 'GET',
      url: '/api/rc/cart_items',
      dataType: 'JSON',
      success: (resp) => {
        return resp;
      },
      error: (err) => {
        return err;
      }
    });
  }

  setup() {
    console.log("ShoppingCart#setup");

    return this.getCartItems().then((data) => {
      this.ready = true;
      _.each(data.items, (rawCartItem) => {
        this.addCartItem(new CartItem(rawCartItem));
      });
    });
  }

  updateRemovedItems(updatedCartItemIds) {
    const cartItemIds = this.items.map(cartItem => cartItem.id);
    _.each(cartItemIds, cartItemId => {
      if (!updatedCartItemIds.includes(cartItemId)) {
        this.removeCartItem(cartItemId);
      }
    });
  }

  updateAddedItems(updatedCartItems) {
    _.each(updatedCartItems, rawCartItem => {
      const currentCartItem = _.find(this.items, item => item.id == rawCartItem.id);

      if (currentCartItem) {
        currentCartItem.merge(rawCartItem);
      } else {
        this.addCartItem(new CartItem(rawCartItem));
      }
    });
  }

  sync(cmeItemRemoved = false) {
    return $.ajax({
      method: 'GET',
      url: '/cart_items',
      data: {
        id: this.items[0] ? this.items[0].id : 0,
        coupon: this.coupon.code || ''
      },
      dataType: 'JSON',
      success: (data) => {
        this.coupon = data.coupon;

        // Update any removed items from backend (e.g. already redeemed cme cart items)
        if (data.removedCme || cmeItemRemoved) {
          this.updateRemovedItems(data.items.map(item => item.id));
          this.showCmeRestrictedModal();
        }

        // Update any added cart items from backend
        this.updateAddedItems(data.items);

        // Now the cart is set
        this.ready = true;

        // Reset referral if our total is less than 10
        if (this.total() <= 10) {
          this.referral.reset();
        }
      }
    });
  }

  submitPurchase(cardData) {
    return $.ajax({
      url: '/invoices',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        stripe_card: {
          token: cardData.token,
          card_type: cardData.card_type,
          card_last4: cardData.card_last4,
          card_zip_code: cardData.card_zip_code,
          card_exp_month: cardData.card_exp_month,
          card_exp_year: cardData.card_exp_year
        },
        invoice_extra_informations: {
          npi_name: this.additional_info.npi_name,
          npi_number: this.additional_info.npi_number,
          phone_number: this.additional_info.phone_number,
          mailing_address: this.additional_info.mailing_address
        },
        referral_email: this.referral.email,
        coupon_code: this.coupon.code,
        delayed_cart_item_ids: _.map(_.filter(this.items, 'delayed'), 'id')
      })
    });
  }

  isEmpty() {
    return _.isEmpty(this.items);
  }

  containsStudentPurchase() {
    return _.some(this.items, item => item.product.isStudentPurchaseOption);
  }

  primaryItems() {
    return _.reject(this.items, 'primaryCartItemId');
  }

  addCartItem(cartItem) {
    this.items.push(cartItem);
  }

  removeCartItem(cartItemId) {
    const indexToRemove = _.findIndex(this.items, item => item.id === cartItemId);

    if (indexToRemove > -1) {
      this.items.splice(indexToRemove, 1);
    } else {
      console.warn('no cart item found', cartItemId, this.items);
    }

    if (this.containsOnlyTrialItems()) {
      this.referral.reset();
    }
  }

  descriptorForDuration(duration) {
    return {
      10: { title: 'Trial', length: '10 Days' },
      31: { title: 'Cram', length: '1 Month' },
      93: { title: 'Prepare', length: '3 Months' },
      185: { title: 'Master', length: '6 Months' },
      365: { title: 'Year', length: '12 Months' }
    }[duration];
  }

  containsOnlyTrialItems() {
    return _.every(this.items, item => item.product.isTrialProduct);
  }

  mostRecentPrimaryCartItem() {
    return _.last(_.sortBy(this.primaryItems(), 'createdAt'));
  }

  mostRecentPrimaryCartItemAndAddons() {
    const primaries = [this.mostRecentPrimaryCartItem()];
    const itemsToDisplay = [this.mostRecentPrimaryCartItem()];
    let allAvailableItems = _.clone(this.items);

    while (_.some(primaries)) {
      const primary = primaries.pop();

      _.each(allAvailableItems, item => {
        if (item.primaryCartItemId === primary.id) {
          itemsToDisplay.push(item);
          primaries.push(item);

          allAvailableItems = _.reject(allAvailableItems, availableItem => availableItem.id === item.id);
        }
      });
    }

    return itemsToDisplay;
  }

  purchaseData() {
    return {
      question_banks: _.map(this.allBanks, bank => setupBank(bank)),
      coupon: this.coupon.code,
      stripe_card: this.stripe_card,
      card_zip_code: this.card_zip_code
    };
  }

  includesProductId(productId) {
    return _.some(_.map(this.items, 'product'), product => product.id === productId);
  }

  includesAnyProduct(products) {
    const currentProductsIds = _.map(_.flatten(_.map(this.items, 'product')), 'id');
    const crossSellProductIds = _.map(products, 'id');

    return _.some(_.intersection(currentProductsIds, crossSellProductIds));
  }

  handleAnyGiftCard() {
    return this.getCartItems().then(this.checkCartItems.bind(this));
  }

  checkCartItems(resp) {
    const cartItems = resp.items;
    return cartItems.some(cartItem => {
      if (cartItem.product.category === 'giftcard' || cartItem.product.category === 'variable_giftcard') {
        this.hasGiftCard = true; // heyo side effect
        return true;
      }
    });
  }

  syncCustData(user) {
    if (!this.hasGiftCard && user.signed_in) {
      return user.getCustData();
    }
  }

  incrementCheckoutStep(user) {
    if (user.signed_in === false) {
      this.currStep = 'register';
    } else {
      if (this.hasGiftCard && this.additional_info.mailing_address === null) {
        this.currStep = 'checkout-requirement';
      } else {
        this.currStep = 'payment';
      }
    }
  }

  total() {
    const prices_total = _.sum(_.map(this.items, item => item.finalPrice()));
    return this.referral.valid ? prices_total - 10 : prices_total;
  }

  totalWithTax(salesTax) {
    return this.total() + salesTax;
  }

  rawTotal() {
    return _.sum(_.map(this.items, 'price'));
  }

  amountSaved() {
    return this.rawTotal() - this.total();
  }

  hasAnyDelayableProducts() {
    return _.some(_.map(this.items, 'product'), product => product.isEligibleForDelay());
  }

  hasCheckoutRequirement() {
    return _.some(_.map(this.items, 'product'), product => product.hasCheckoutRequirement);
  }

  canAcceptReferral() {
    if (this.currStep !== 'payment') return false;
    return !this.containsOnlyTrialItems() && this.total() >= 10 && !this.containsStudentPurchase();
  }

  canAcceptPromo() {
    return !this.containsOnlyTrialItems() && !this.containsStudentPurchase();
  }

  calculateTax(zipcode, subtotal) {
    const deferred = Q.defer();
    let salesTax = 0;

    $.ajax({
      method: 'GET',
      url: `/taxes?sub_total=${subtotal * 100}&zip_code=${zipcode}`,
      dataType: 'JSON',
      success: (data, status) => {
        salesTax = data / 100;
        deferred.resolve(salesTax);
      },
      error: (data, status) => {
        salesTax = 0;
        deferred.resolve(salesTax);
      }
    });

    return deferred.promise;
  }

  fetchCustomerSession(userId) {
    return $.ajax({
      method: 'POST',
      url: `/api/ati/v1/users/${userId}/stripe_customers/customer_session`,
      dataType: 'JSON',
      success: (response) => {
        return response;
      },
      error: (error) => {
        return error;
      }
    });
  }

  fetchPaymentIntent(userId, zipCode) {
    const payload = {
      stripe_customer: {
        zip_code: zipCode,
        referral_email: this.referral.email,
        coupon_code: this.coupon.code,
        delayed_cart_item_ids: this.items.filter(item => item.delayed).map(item => item.id)
      }
    };

    return $.ajax({
      method: 'POST',
      url: `/api/ati/v1/users/${userId}/stripe_customers/payment_intent`,
      dataType: 'JSON',
      data: payload,
      success: (response) => {
        return response
      },
      error: (error) => {
        return error;
      }
    });
  }
}

const setupBank = (bank) => {
  const data = bank.purchaseData();
  return data;
};

export default ShoppingCart;
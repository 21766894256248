import { Bugfender, LogLevel } from "@bugfender/sdk";
import Config from "config";

// Add any redux action to blacklist from bugfender log console
const actionsBlacklist = [];
const bugfenderReduxLogger = store => next => (action) => {
  if (Config.env !== "development" && !actionsBlacklist.includes(action.type)) {
    try {
      Bugfender.sendLog({ level: LogLevel.Debug, tag: "Action", text: JSON.stringify(action) });
    } catch (e) {
      console.log(e);
    }
  }

  const result = next(action);
  return result;
};

const bugfenderReduxExceptionReporter = store => next => (action) => {
  try {
    return next(action);
  } catch (err) {
    if (Config.env !== "development") {
      Bugfender.sendLog({ level: LogLevel.Error, tag: "Action", text: JSON.stringify(err) });
    }
    throw err;
  }
};

export { bugfenderReduxLogger, bugfenderReduxExceptionReporter };

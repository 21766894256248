import api from 'helpers/api';

export const BOARD_EXAM_DATES_FETCHING = 'BOARD_EXAM_DATES_FETCHING';
export const BOARD_EXAM_DATES_FETCHED = 'BOARD_EXAM_DATES_FETCHED';
export const BOARD_EXAM_DATES_FETCH_ERROR = 'BOARD_EXAM_DATES_FETCH_ERROR';

const boardExamDatesFetchKey = () => 'board_exam_dates';

export const boardExamDatesFetch = () => (dispatch, getState) => {
  const { loading, session } = getState();
  const key = boardExamDatesFetchKey();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get('board_exam_dates')
    .then((response) => {
      dispatch({
        type: BOARD_EXAM_DATES_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: BOARD_EXAM_DATES_FETCH_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: BOARD_EXAM_DATES_FETCHING,
    payload: { key, promise }
  });

  return promise;
};

boardExamDatesFetch.getKey = boardExamDatesFetchKey;

export const BOARD_EXAM_DATE_UPDATED = "BOARD_EXAM_DATE_UPDATED";
export const BOARD_EXAM_DATE_UPDATING = "BOARD_EXAM_DATE_UPDATING";
export const BOARD_EXAM_DATE_UPDATE_ERROR = "BOARD_EXAM_DATE_UPDATE_ERROR";
const updateBoardExamDateKey = boardExamDateId => `board_exam_dates/${boardExamDateId}`;
export const updateBoardExamDate = (boardExamDateId, follow, postpone, examDate, studyFrequency) => (dispatch, getState) => {
  const key = updateBoardExamDateKey(boardExamDateId);
  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.put(`board_exam_dates/${boardExamDateId}`, {
    exam_date: examDate, follow_up: follow, postponed: postpone, study_frequency: studyFrequency
  })
    .then((response) => {
      dispatch({
        type: BOARD_EXAM_DATE_UPDATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: BOARD_EXAM_DATE_UPDATE_ERROR,
        payload: { error, key }
      });
      throw error;
    });
  dispatch({
    type: BOARD_EXAM_DATE_UPDATING,
    payload: { key, promise }
  });

  return promise;
};
updateBoardExamDate.getKey = updateBoardExamDateKey;
